<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        If an atom lies
        <number-value :value="energy" />
        <stemble-latex content="$\mathrm{J}$" />
        above the ground state, determine the wavelength of light (in nm) that would be emitted upon
        relaxation back to the ground state.
      </p>

      <calculation-input
        v-model="inputs.input1"
        :prepend-text="'$\\lambda:$'"
        append-text="$\text{nm}$"
        show-helpful-hint
        helpful-hint-position="below"
        class="mb-2"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question13',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    energy() {
      return this.taskState.getValueBySymbol('energy').content;
    },
  },
};
</script>
